export function interfaces(): string {
  return 'interfaces';
}

// this is just for testing

interface PropertyData<T> {
  value: T;
  log: any[];
}

interface Parts {
  partsProcess: PropertyData<string>;
  partsPrinter: PropertyData<boolean>;
}

interface Service {
  serviceProcess: PropertyData<string>;
  servicePrinter: PropertyData<boolean>;
}

type DiscoveryData = Parts | Service;
type DiscoveryDataKeys = keyof Parts | keyof Service;

type Keys = keyof DiscoveryData;

type Narrowed<T extends DiscoveryData> = 'partsProcess' extends keyof T
  ? Parts
  : never;

type Narrow<T extends DiscoveryData> = T extends Parts ? Parts : Service;

const parts: DiscoveryData = {
  partsProcess: {
    value: 'test',
    log: [],
  },
  partsPrinter: {
    value: true,
    log: [],
  },
};

const mykey: DiscoveryDataKeys = 'partsProcess';
