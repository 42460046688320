import { DataInitializerBasic } from './core-module.interfaces';
import {
  ComparisonMethod,
  IDiscoveryDataProperty,
  PromptText,
  QuestionRequirementType,
  ResponseValidation,
  TAllGroupKeys,
  TSubmoduleMetaCompletion,
  isKeyOf,
} from './core-submodule.interfaces';
import { QuestionComponentType } from './question-component-types.interfaces';
import { SubmoduleGroup } from './submodule-group.interfaces';

type GenericStructure<GroupKeys extends string> = {
  [k in GroupKeys]: TGenericGroup<string>;
};

export type TGenericGroup<DataKeys extends string> = {
  groupLabel: string;
  groupUrl: string;
  groupType: 'standard' | 'vendor';
  groupOrder?: number;
  questions: {
    [DataKey in DataKeys]: {
      questionKey: DataKey;
      label: string;
      prompt: PromptText[];
      subprompt: PromptText[];
      allowSkip: boolean;
      componentType: QuestionComponentType;
      requirement: QuestionRequirementType;
      options: Array<{
        label: string;
        value: any;
      }>;
      validation: ResponseValidation[];
      dependencies: Array<{
        comparisonMethod: ComparisonMethod;
        comparisonValue: any;
        dependentKeys: DataKeys[];
      }>;
    };
  };
};

export class SubmoduleCompletionService<
  DataKeys extends string, // all data keys for this submodule
  GroupKeys extends TAllGroupKeys,
> {
  data: Record<DataKeys, IDiscoveryDataProperty<any>>;
  structure: GenericStructure<GroupKeys>;
  groups: SubmoduleGroup<GroupKeys, string>[] = [];

  constructor(
    data: Record<DataKeys, IDiscoveryDataProperty<any>>,
    structure: GenericStructure<GroupKeys>,
  ) {
    this.data = data;
    this.structure = structure;
    // log data and structure
    for (const groupKey in structure) {
      const group = structure[groupKey];
      type GroupDataKeys = keyof (typeof group)['questions'];
      const groupData = {} as Record<
        GroupDataKeys,
        IDiscoveryDataProperty<any>
      >;
      for (const questionKey in group['questions']) {
        if (isKeyOf(data, questionKey) && data[questionKey] !== undefined) {
          groupData[questionKey] = data[questionKey];
          // below we handle keys that are undefined in the data
        } else {
          groupData[questionKey as keyof (typeof group)['questions']] =
            new DataInitializerBasic();
        }
      }
      const submoduleGroup = new SubmoduleGroup(
        groupKey,
        groupData,
        group.questions,
      );
      this.groups.push(submoduleGroup);
    }
  }

  getCompletion(): TSubmoduleMetaCompletion<GroupKeys> {
    const completion: TSubmoduleMetaCompletion<GroupKeys> = {
      total: 0,
      complete: 0,
      groups: {},
    } as TSubmoduleMetaCompletion<GroupKeys>;
    for (const group of this.groups) {
      const { identifier, ...groupCompletion } = group.getCompletion();
      completion.groups[group.identifier] = groupCompletion;
      completion.total += groupCompletion.total;
      completion.complete += groupCompletion.complete;
    }
    return completion;
  }
}
