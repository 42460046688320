import { Types } from 'mongoose';
import {
  IVendorQuestion,
  IVendorReference,
  IVendorQuestionCreateDto,
} from './vendor-question.interfaces';
import { ModuleType } from '../modules/core/core-module.interfaces';

export class VendorQuestion implements IVendorQuestion {
  _id: string;
  questionKey: string;
  moduleType: ModuleType;
  label: string;
  desc: string;
  vendors: IVendorReference[];
  constructor(initializer: IVendorQuestion | IVendorQuestionCreateDto) {
    if ('_id' in initializer) {
      Object.assign(this, JSON.parse(JSON.stringify(initializer)));
    } else {
      this._id = new Types.ObjectId().toString();
      this.label = '';
      this.desc = '';
      this.vendors = [];
      Object.assign(this, initializer);
    }
  }

  addVendor(ref: IVendorReference) {
    const index = this.vendors.findIndex((v) => v.vendorId === ref.vendorId);
    if (index === -1) {
      this.vendors.push(ref);
    }
  }

  removeVendor(id: string) {
    const index = this.vendors.findIndex((v) => v.vendorId === id);
    if (index !== -1) {
      this.vendors.splice(index, 1);
    }
  }
}
