/**
 * Interface for select options for dropdowns or select inputs
 *
 * @typeParam T - The type of the value
 */
export interface SelectOption<T extends string | boolean | number> {
  label: string;
  value: T;
  disabled?: boolean;
}
