interface EmailDto {
  recipientEmail: string;
  recipientFirstName: string;
  recipientLastName: string;
}

interface EmailConfirmationDto extends EmailDto {
  userId: string;
  confirmationCode: string;
}

export { EmailConfirmationDto };
