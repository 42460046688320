import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopoverHostDirective } from '../directives/popover-host.directive';

@Component({
  selector: 'dominion-discovery-text-input',
  templateUrl: './discovery-text-input.component.html',
  styleUrls: ['./discovery-text-input.component.css'],
  host: {
    class: 'flex flex-col flex-1',
  },
})
export class DiscoveryTextInputComponent implements OnChanges {
  public form: FormGroup;
  public isEditing: boolean = false;
  public serverErrMsg: string | undefined;

  @Input('text') text: string | undefined | null;
  @Input('errorMessage') errorMessage: string | undefined;

  @Output() saved: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(PopoverHostDirective, {
    static: false,
  })
  popoverHost: PopoverHostDirective | undefined;

  @ViewChild('inputEl', { static: false }) inputEl:
    | ElementRef<HTMLInputElement>
    | undefined;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      text: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['text']) {
      this.setInitialValue();
    }
  }

  setInitialValue() {
    this.form.get('text')!.setValue(this.text);
  }

  setFocus() {
    this.inputEl?.nativeElement.focus();
  }

  save() {
    this.resetErrors();
    if (this.form.get('text')!.valid) {
      this.saved.emit(this.form.get('text')!.value);
      return;
    }
    this.errorMessage = 'Please enter a value';
    this.showError();
  }

  private showError() {
    this.popoverHost?.show();
  }

  private hideError() {
    this.popoverHost?.hide();
  }

  resetErrors() {
    this.serverErrMsg = undefined;
    this.hideError();
  }
}
