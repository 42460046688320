import { ICoreSubmodule } from '../../core/core-submodule.interfaces';
import { CoreSubmodule } from '../../core/core-submodule.class';
import {
  AccountingPreliminaryData,
  accountingPreliminaryStructure,
} from './accounting-prelim-data.interfaces';

export class AccountingPreliminarySubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'accounting-preliminary'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'accounting' as const;
  submoduleType = 'preliminary' as const;
  discriminator = 'accounting-preliminary' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupEarlyOpen: {
          total: 0,
          complete: 0,
        },
        groupFiscalYear: {
          total: 0,
          complete: 0,
        },
        groupForecasting: {
          total: 0,
          complete: 0,
        },
        groupGL: {
          total: 0,
          complete: 0,
        },
        groupIds: {
          total: 0,
          complete: 0,
        },
        groupMisc: {
          total: 0,
          complete: 0,
        },
        groupMulti: {
          total: 0,
          complete: 0,
        },
        groupOEMPayables: {
          total: 0,
          complete: 0,
        },
        groupPositivePay: {
          total: 0,
          complete: 0,
        },
        groupVendors: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: AccountingPreliminaryData;
  override structure = accountingPreliminaryStructure['groups'];
  constructor(initial?: ICoreSubmodule<'accounting-preliminary'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new AccountingPreliminaryData();
    }
  }
}
