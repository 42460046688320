import { CoreSubmodule } from '../../core/core-submodule.class';
import { ICoreSubmodule } from '../../core/core-submodule.interfaces';
import {
  ServicePreliminaryData,
  servicePreliminaryStructure,
} from './service-prelim-data.interfaces';

export class ServicePreliminarySubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'service-preliminary'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'service' as const;
  submoduleType = 'preliminary' as const;
  discriminator = 'service-preliminary' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupDispatching: {
          total: 0,
          complete: 0,
        },
        groupWarrantyClaims: {
          total: 0,
          complete: 0,
        },
        groupTechniciansDMS: {
          total: 0,
          complete: 0,
        },
        groupQuickLube: {
          total: 0,
          complete: 0,
        },
        groupBodyShop: {
          total: 0,
          complete: 0,
        },
        groupTechnicianAdditionalPay: {
          total: 0,
          complete: 0,
        },
        groupPrinters: {
          total: 0,
          complete: 0,
        },
        groupVendors: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: ServicePreliminaryData;
  override structure = servicePreliminaryStructure['groups'];
  // can be initialized with DB data or with none
  constructor(initial?: ICoreSubmodule<'service-preliminary'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new ServicePreliminaryData();
    }
  }
}
