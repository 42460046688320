export interface IFileUploadReference {
  _id: string;
  companyId: string;
  moduleId: string;
  key: string;
  name: string;
  dateUploaded: Date;
  questionKey: string;
}

export interface IFileUploadDto {
  name: string;
  submoduleId: string;
  field: string;
  file: Buffer;
}

export interface IFileGetDto {
  companyId: string;
  fileId: string;
}

export interface IFileDeleteDto {
  companyId: string;
  submoduleId: string;
  fileId: string;
  field: string;
}

export interface IFile extends IFileUploadReference {
  file: Buffer;
}
