import {
  ComparisonMethod,
  IDiscoveryDataProperty,
  PromptText,
  QuestionComponentType,
  QuestionSections,
  QuestionRequirementType,
  ResponseValidation,
  TAllGroupKeys,
} from '@dominion/interfaces';

export interface ISubmoduleGroupCompletionStatus {
  identifier: TAllGroupKeys;
  complete: number; // number of completed keys
  total: number; // total number of required keys based on dependency analysis
}

//
//
//
//
//

export type TGenericQStruct<DataKeys> = {
  questionKey: DataKeys;
  label: string;
  prompt: PromptText[];
  subprompt: PromptText[];
  componentType: QuestionComponentType;
  requirement: QuestionRequirementType;
  allowSkip: boolean;
  order?: number;
  sections?: QuestionSections;
  options: Array<{
    label: string;
    value: any;
  }>;
  validation: ResponseValidation[];
  dependencies: Array<{
    comparisonMethod: ComparisonMethod;
    comparisonValue: any;
    dependentKeys: DataKeys[];
  }>;
};

export class SubmoduleGroup<
  GroupKey extends TAllGroupKeys,
  DataKeys extends string,
> {
  data: Record<DataKeys, IDiscoveryDataProperty<any>>;
  structure: Record<DataKeys, TGenericQStruct<DataKeys>>;
  identifier: GroupKey;
  totalCounter: number = 0;
  requiredKeys: Set<DataKeys> = new Set();

  //
  constructor(
    identifier: GroupKey,
    data: Record<DataKeys, IDiscoveryDataProperty<any>>,
    structure: Record<DataKeys, TGenericQStruct<DataKeys>>,
  ) {
    this.data = data;
    this.structure = structure;
    this.identifier = identifier;
  }
  //

  public getCompletion(): ISubmoduleGroupCompletionStatus {
    // check for all or nothing completion first
    // if partial completion, then proceed to dependency analysis
    // log the data and the structure
    const basicCompletion = this.getBasicCompletion();
    if (basicCompletion !== null) {
      return basicCompletion;
    }
    this.getRequiredKeys();
    return this.getCompletionFromRequiredKeys();
  }

  // this checks the easy cases where all questions are complete or all questions are incomplete (i.e., dependencies don't matter)
  getBasicCompletion(): ISubmoduleGroupCompletionStatus | null {
    let completeCounter = 0;
    for (const key in this.data) {
      if (this.data[key]['value'] !== null) {
        if (Array.isArray(this.data[key]['value'])) {
          if (this.data[key]['value'].length > 0) {
            completeCounter++;
          }
        } else {
          completeCounter++;
        }
      }
      this.totalCounter++;
    }
    if (completeCounter === 0) {
      return {
        identifier: this.identifier,
        complete: 0,
        total: this.totalCounter,
      };
    }
    if (completeCounter === this.totalCounter) {
      return {
        identifier: this.identifier,
        complete: this.totalCounter,
        total: this.totalCounter,
      };
    }
    return null;
  }

  // some discovery questions have logical interdependencies
  // these interdependencies are defined by the structure property
  // this function uses the structure property and already-provided responses from the data property to determine which keys in this group are required to be answered
  getRequiredKeys() {
    for (const questionKey in this.structure) {
      let question = this.structure[questionKey];
      if (question.requirement === 'required') {
        this.requiredKeys.add(questionKey);
      }
      for (const dependency of question.dependencies) {
        if (dependency.comparisonMethod === 'non-null') {
          if (this.data[questionKey]['value'] !== null) {
            dependency.dependentKeys.forEach((key) =>
              this.requiredKeys.add(key),
            ); // add all of the dependent keys
            this.requiredKeys.add(questionKey); // add the parent question key
          }
        }
        if (dependency.comparisonMethod === 'equals') {
          if (
            this.data[questionKey as keyof typeof this.data]['value'] ===
            dependency.comparisonValue
          ) {
            dependency.dependentKeys.forEach(
              (key) => this.requiredKeys.add(key), // add the dependent keys
            );
            this.requiredKeys.add(questionKey); // add the parent question key
          }
        }
      }
    }
  }

  // determine completion status based on the required keys set
  getCompletionFromRequiredKeys() {
    let complete = 0;
    for (const key of this.requiredKeys) {
      if (this.data[key]['value'] !== null) {
        if (Array.isArray(this.data[key]['value'])) {
          if (this.data[key]['value'].length > 0) {
            complete++;
          }
        } else {
          complete++;
        }
      }
    }
    return {
      identifier: this.identifier,
      complete,
      total: this.requiredKeys.size,
    };
  }
}
