import {
  AccountingPreliminaryData,
  accountingPreliminaryStructure,
} from '../modules/accounting/prelim/accounting-prelim-data.interfaces';
import {
  ICoreSubmodule,
  TSubmoduleDiscriminators,
} from '../modules/core/core-submodule.interfaces';

export class FakeSubmodule implements ICoreSubmodule<'accounting-preliminary'> {
  _id: string = 's1';
  companyId: string = 'c1';
  moduleId: string = 'm1';
  moduleType = 'accounting' as const;
  submoduleType = 'preliminary' as const;
  discriminator = 'accounting-preliminary' as const;
  meta: ICoreSubmodule<TSubmoduleDiscriminators>['meta'] = {
    completion: {
      total: 100,
      complete: 0,
      groups: {
        group1: {
          total: 100,
          complete: 0,
        },
      },
    },
  };
  structure = accountingPreliminaryStructure.groups;
  data = new AccountingPreliminaryData();

  constructor(initializer?: Partial<ICoreSubmodule<TSubmoduleDiscriminators>>) {
    if (initializer) {
      Object.assign(this, initializer);
    }
  }
}
