import { Types, Model, HydratedDocument } from 'mongoose';
import {
  IExternalAssignments,
  IUserAuthorizations,
  IUserCreateDto,
  IUserDocument,
  IUserModelOverrides,
} from './user.interfaces';
import { CompanyAuthRole } from '../authorization/authorization.interfaces';
import { ICompanyDocument } from '@dominion/interfaces';

//
//
//
//
// INVITED USER CORE

export interface IInvitedUser {
  _id: string;
  status: 'invited';
  userType: 'external';
  email: string;
  invite: {
    invitedOn: Date;
    invitedBy: string;
  };
  companies: string[];
  external: {
    assignments: IExternalAssignments[];
  };
  authorizations: IUserAuthorizations;
  isDeactivated: boolean;
}

//
//
//
//
// INVITED USER MODEL

// overrides
export interface IInvitedUserModelOverrides extends IUserModelOverrides {
  invite: {
    invitedOn: Date;
    invitedBy: Types.ObjectId;
  };
}

// model
export interface IInvitedUserModel
  extends Model<IInvitedUser, {}, IInvitedUserModelOverrides> {}

// document
export interface IInvitedUserDocument
  extends HydratedDocument<IInvitedUser, IInvitedUserModelOverrides> {}

// population options
export interface IInvitedUserDocumentPop
  extends Omit<IInvitedUserDocument, 'companies' | 'invite'> {
  companies: Types.DocumentArray<ICompanyDocument>;
  invite: {
    invitedOn: Date;
    invitedBy: IUserDocument;
  };
}

//
//
//
//
// CRUD

//
//
// Create
export interface IInvitedUserCreate extends Pick<IInvitedUser, 'email'> {
  companyRoles: IInviteCompanyRole[];
  companyStrategy: 'all' | 'one';
}

// initializer
export interface IInvitedUserInitializer extends Omit<IInvitedUser, '_id'> {}

// email dto
export interface IInvitedUserEmailDto {
  _id: Types.ObjectId;
  email: string;
}

// register dto
export interface IInvitedUserRegisterDto
  extends Pick<
    IUserCreateDto,
    'firstName' | 'lastName' | 'department' | 'jobTitle' | 'password'
  > {
  _id: string;
}

//
//
// Read

export interface IInvitedUserReadBrief
  extends Pick<
    IInvitedUser,
    '_id' | 'invite' | 'status' | 'email' | 'isDeactivated'
  > {}

export interface IInvitedUserMeta {
  count: number;
  users: IInvitedUserReadBrief[];
}

export interface IInvitedUserRead
  extends IInvitedUserReadBrief,
    Pick<
      IInvitedUser,
      'external' | 'companies' | 'authorizations' | 'userType' | 'isDeactivated'
    > {}

//
//
//
//
// UTILITIES

export interface IInviteCompanyRole {
  companyId: string;
  role: CompanyAuthRole;
}
