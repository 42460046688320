import { IRequestUserPayload } from '../auth.interfaces';
import { UserType } from '../user/user.interfaces';

export class ResponseLog {
  actorId: string | null = null;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  userType: UserType | null = null;
  date: Date;
  value: any;

  constructor(actor: Partial<Omit<ResponseLog, 'date' | 'value'>>, value: any) {
    this.actorId = actor.actorId ? actor.actorId : null;
    this.firstName = actor.firstName ? actor.firstName : '';
    this.lastName = actor.lastName ? actor.lastName : '';
    this.email = actor.email ? actor.email : '';
    this.userType = actor.userType ? actor.userType : null;
    this.date = new Date();
    this.value = value;
  }
}

export interface ILogActor
  extends Partial<Omit<ResponseLog, 'value' | 'date'>> {}

export function payloadToLogActor(payload: IRequestUserPayload): ILogActor {
  return {
    actorId: payload._id,
    email: payload.email,
    firstName: payload.firstName,
    lastName: payload.lastName,
    userType: payload.userType,
  };
}
