import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dominion-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  isLoading = true;

  constructor() {}

  throwTest() {
    throw new Error('Test Error');
  }

  ngOnInit() {}
}
