import { Types } from 'mongoose';

type MockQueryParameter = { [key: string]: unknown } | string;

interface MockQuery<MockDocument> {
  null: boolean;
  error: boolean;
  undefined: boolean;
  exec(): Promise<MockDocument | MockDocument[] | void | null>;
  populate(): this;
  lean(): this;
}

interface MockModel<MockQuery> {
  find: (filter: object) => MockQuery;
  findById: (_id: string) => MockQuery;
  findOne: (filter: object) => MockQuery;
  findByIdAndDelete: (_id: string) => MockQuery;
}

interface MockDocument<DocumentType> {
  _id: Types.ObjectId;
  saved: boolean;
  removed: boolean;
  isSet: boolean;
  save(): Promise<DocumentType>;
  remove(): Promise<DocumentType>;
}

interface MockQueryProperties {
  null: boolean;
  undefined: boolean;
  error: boolean;
}

const mockQueryPropHandler = function (
  arg: MockQueryParameter,
): MockQueryProperties {
  const props: MockQueryProperties = {
    null: false,
    undefined: false,
    error: false,
  };
  if (typeof arg === 'string') {
    if (arg === 'null') {
      props.null = true;
    } else if (arg === 'undefined') {
      props.undefined = true;
    } else if (arg === 'error') {
      props.error = true;
    }
  }
  if (typeof arg === 'object') {
    for (const key in arg) {
      if (arg[key] === 'null') {
        props.null = true;
      } else if (arg[key] === 'error') {
        props.error = true;
      } else if (arg[key] === 'undefined') {
        props.undefined = true;
      }
    }
  }
  return props;
};

export {
  MockQueryProperties,
  mockQueryPropHandler,
  MockModel,
  MockDocument,
  MockQuery,
  MockQueryParameter,
};
