import { CoreSubmodule } from '../core/core-submodule.class';
import { ICoreSubmodule } from '../core/core-submodule.interfaces';
import {
  InfraPreliminaryData,
  infraPreliminaryStructure,
} from './infra-prelim-data.interfaces';

export class InfraPreliminarySubmodule
  extends CoreSubmodule
  implements ICoreSubmodule<'infrastructure-preliminary'>
{
  _id: string;
  companyId: string;
  moduleId: string;
  moduleType = 'infrastructure' as const;
  submoduleType = 'preliminary' as const;
  discriminator = 'infrastructure-preliminary' as const;
  override meta = {
    completion: {
      total: 0,
      complete: 0,
      groups: {
        groupHardware: {
          total: 0,
          complete: 0,
        },
        groupNetworking: {
          total: 0,
          complete: 0,
        },
      },
    },
  };
  override data: InfraPreliminaryData;
  override structure = infraPreliminaryStructure['groups'];
  constructor(initial?: ICoreSubmodule<'infrastructure-preliminary'>) {
    super();
    if (initial) {
      Object.assign(this, initial);
    } else {
      // initialize with blank data values
      this.data = new InfraPreliminaryData();
    }
  }
}
