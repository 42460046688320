import { Types } from 'mongoose';
import {
  IVendor,
  IStandardVendorCompanyRef,
  IVendorCustom,
  IVendorCustomInitializer,
  IVendorInitializer,
  TVendorStatus,
} from './vendor.interfaces';

// this is what is stored in the vendor collection for all
export class Vendor implements IVendor {
  _id: string;
  providerName: string;
  vendorType: 'standard' = 'standard';
  appName: string;
  status: TVendorStatus;
  desc: string;

  constructor(vendor: IVendor | IVendorInitializer) {
    if ('_id' in vendor) {
      Object.assign(this, JSON.parse(JSON.stringify(vendor)));
    } else {
      this._id = new Types.ObjectId().toString();
      this.providerName = vendor.providerName;
      this.appName = vendor.appName;
      this.status = vendor.status ?? 'inactive';
      this.desc = vendor.desc ?? '';
    }
  }
}

// this is what gets stored on a company
export class VendorCompanyReference implements IStandardVendorCompanyRef {
  vendorId: string;
  keys: string[] = [];
  authorizations: string[] = [];

  constructor(vendor: IStandardVendorCompanyRef) {
    Object.assign(this, JSON.parse(JSON.stringify(vendor)));
  }
}

export class VendorCustom implements IVendorCustom {
  _id: string;
  providerName: string;
  vendorType: 'custom' = 'custom';
  appName: string;
  status: null;
  streams: null;
  desc: null;
  keys: string[] = [];
  authorizations: string[] = [];

  constructor(vendor: IVendorCustom | IVendorCustomInitializer) {
    if ('_id' in vendor) {
      Object.assign(this, JSON.parse(JSON.stringify(vendor)));
    } else {
      this._id = new Types.ObjectId().toString();
      this.providerName = vendor.providerName;
      this.appName = vendor.appName ?? vendor.providerName;
    }
  }
}
