import {
  ComparisonMethod,
  PromptText,
  QuestionRequirementType,
  ResponseValidation,
} from './core-submodule.interfaces';
import { QuestionComponentType } from './question-component-types.interfaces';

export interface CoreQuestionVisual {
  label: string;
  key: string;
  prompt: PromptText[];
  subprompt: PromptText[];
  componentType: QuestionComponentType;
  requirement: QuestionRequirementType;
  options:
    | {
        label: string;
        value: any;
      }[]
    | [];
  validation: ResponseValidation[];
  dependencies:
    | {
        method: ComparisonMethod;
        value: any;
        questions: CoreQuestionVisual[]; // questions again recursive
      }[]
    | [];
}

export interface CoreSubmoduleVisual {
  groups: {
    groupLabel: string;
    groupKey: string;
    groupType: 'vendor' | 'standard';
    questions: CoreQuestionVisual[];
  }[];
}
