import { ICompany } from './company.interfaces';

export type UserExternalAssignments = {
  _id: ICompany['_id'];
  name: ICompany['name'];
  modules: Pick<ICompany['modules'][number], 'name' | '_id' | 'moduleType'>[];
};

export type UserInternalAssignments = {
  _id: ICompany['_id'];
  name: ICompany['name'];
  preferredInstallDate: ICompany['preferredInstallDate'];
  goLiveDate: ICompany['goLiveDate'];
};
