export type ResourceType = 'dominion' | 'company' | 'module';

export type DominionAuthRole =
  | 'super'
  | 'admin'
  | 'projectmanager'
  | 'deploymentmanager'
  | 'deploymenttechnician'
  | 'viewer';

export type CompanyAuthRole = 'super' | 'admin' | 'manager';

export type Permissions = 'create' | 'read' | 'update' | 'delete';

export interface IAuthorization {
  type: ResourceType;
  id: string;
  roles: (DominionAuthRole | CompanyAuthRole)[];
  permissions: Permissions[];
}

export interface IResourceAuth {
  authorizations: IAuthorization[];
}

export type AuthorizationStrategy = IResourceAuth[];
