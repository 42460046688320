import { IVendorAuthRef } from '@dominion/interfaces';

export interface IVendorAuthorization {
  user: {
    userId: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    ip: string;
  };
  vendors: IVendorAuthRef[];
  vendorsAdded: IVendorAuthRef[];
  vendorsRemoved: IVendorAuthRef[];
  date: Date;
}

export class VendorAuthorization implements IVendorAuthorization {
  user: {
    userId: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    ip: string;
  };
  vendors: IVendorAuthRef[] = [];
  vendorsAdded: IVendorAuthRef[] = [];
  vendorsRemoved: IVendorAuthRef[] = [];
  date: Date;

  constructor(
    user: IVendorAuthorization['user'],
    vendors: IVendorAuthRef[],
    lastAuth?: IVendorAuthorization,
  ) {
    this.user = user;
    this.vendors = vendors;
    this.date = new Date();
    this.setAddedVendors(lastAuth);
    if (lastAuth) {
      this.setRemovedVendors(lastAuth);
    }
  }

  setAddedVendors(lastAuth?: IVendorAuthorization) {
    if (lastAuth) {
      this.vendors.forEach((vendor) => {
        const inLastAuth = lastAuth.vendors.findIndex((v) => {
          return v._id === vendor._id;
        });
        if (inLastAuth === -1) {
          this.vendorsAdded.push(vendor);
        }
      });
    } else {
      this.vendorsAdded = this.vendors;
    }
  }

  setRemovedVendors(lastAuth: IVendorAuthorization) {
    lastAuth.vendors.forEach((vendor) => {
      const inThisAuth = this.vendors.findIndex((v) => {
        return v._id === vendor._id;
      });
      if (inThisAuth === -1) {
        this.vendorsRemoved.push(vendor);
      }
    });
  }
}
