export type SectionOption = {
  enabled?: boolean;
  required?: boolean;
};

export type ContactSections = {
  orgName?: SectionOption;
  orgAddress?: SectionOption;
  orgEmail?: SectionOption;
  orgPhone?: SectionOption;
  contactName?: SectionOption;
  notes?: SectionOption;
};

export type QuestionSections = ContactSections | undefined;
