export type CompanyRole =
  | 'cfo'
  | 'controller'
  | 'accounting_manager'
  | 'accounts_payable_clerk'
  | 'accounts_receivable_clerk'
  | 'accounting_clerk'
  | 'billing_clerk'
  | 'inventory_clerk'
  | 'hr_manager'
  | 'hr_payroll_assistant'
  | 'payroll_clerk'
  | 'general_manager'
  | 'general_sales_manager'
  | 'sales_manager'
  | 'fi_manager'
  | 'sales_cashier'
  | 'parts_manager'
  | 'parts_counter'
  | 'service_manager'
  | 'service_advisor'
  | 'warranty_clerk'
  | 'salesperson'
  | 'technician';

export const CompanyRoleList: CompanyRole[] = [
  'accounting_clerk',
  'accounting_manager',
  'accounts_payable_clerk',
  'accounts_receivable_clerk',
  'billing_clerk',
  'controller',
  'cfo',
  'fi_manager',
  'general_manager',
  'general_sales_manager',
  'hr_manager',
  'hr_payroll_assistant',
  'inventory_clerk',
  'payroll_clerk',
  'parts_counter',
  'parts_manager',
  'sales_cashier',
  'sales_manager',
  'salesperson',
  'service_advisor',
  'service_manager',
  'technician',
  'warranty_clerk',
];
