import { Pipe, PipeTransform } from '@angular/core';
import { ModuleType } from '@dominion/interfaces';

@Pipe({
  name: 'moduleName',
})
export class ModuleNamePipe implements PipeTransform {
  transform(
    value: string | undefined,
    moduleType: ModuleType | undefined,
  ): string {
    if (value) {
      return value;
    } else {
      switch (moduleType) {
        case 'accounting':
          return 'Accounting Module';
        case 'fi':
          return 'F&I Module';
        case 'parts':
          return 'Parts Module';
        case 'payroll':
          return 'Payroll Module';
        case 'sales':
          return 'Sales Module';
        case 'service':
          return 'Service Module';
        case 'infrastructure':
          return 'Infrastructure Module';
        default:
          return 'Module';
      }
    }
  }
}
