import { UserType } from './user/user.interfaces';

export interface IRequestUserPayload {
  email: string;
  _id: string;
  firstName: string;
  lastName: string;
  userType: UserType;
  isDeactivated: boolean;
}

export interface IChangePasswordDto {
  oldPassword: string;
  newPassword: string;
}

export interface IForgotPasswordDto {
  email: string;
}

export interface IResetPasswordDto {
  userId: string;
  resetToken: string;
  password: string;
}

export interface JwtAccessToken {
  access_token: string;
}

export interface INestRequestPayload {
  user: IRequestUserPayload;
}

export interface IResetPasswordEmailDto {
  recipientId: string;
  recipientEmail: string;
  recipientFirstName: string;
  resetToken: string;
}
