import {
  ICoreModule,
  ICoreSubmoduleReference,
  ModuleType,
} from '../modules/core/core-module.interfaces';

export class FakeModule implements ICoreModule {
  _id: string = 'm1';
  name: string = 'Fake Module';
  moduleType: ModuleType = 'accounting';
  desc: string = '';
  dateCreated: Date = new Date();
  createdBy: string = '';
  externalAssigned: string[] = [];
  submodules: {
    preliminary: ICoreSubmoduleReference;
    maintenance: ICoreSubmoduleReference;
    default: ICoreSubmoduleReference;
  } = {
    preliminary: {
      submoduleId: 's1',
      isAvailable: false,
      dueDate: null,
      completion: {
        total: 100,
        complete: 0,
        groups: {
          group1: {
            total: 50,
            complete: 0,
          },
        },
      },
    },
    maintenance: {
      submoduleId: 's2',
      isAvailable: false,
      dueDate: null,
      completion: {
        total: 100,
        complete: 0,
        groups: {
          group1: {
            total: 50,
            complete: 0,
          },
        },
      },
    },
    default: {
      submoduleId: 's3',
      isAvailable: false,
      dueDate: null,
      completion: {
        total: 100,
        complete: 0,
        groups: {
          group1: {
            total: 50,
            complete: 0,
          },
        },
      },
    },
  };
  constructor(initializer?: Partial<ICoreModule>) {
    if (initializer) {
      Object.assign(this, initializer);
    }
  }
}
